import { lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { Box } from "UI/Box";
import { Container } from "UI/Container";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import LazyLoadPage from "pages/LazyLoadPage/LazyLoadPage";

import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { basicTheme, theme } from "theme";
import { useBreakpoints } from "hooks/useBreakpoints";

const MainPage = lazy(() => import("pages/MainPage"));

const App = () => {
    const location = useLocation();
    const { isUpLaptop } = useBreakpoints();

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ bgcolor: basicTheme.palette.mainPalette.secondaryBlack }}>
          <Container
            sx={{
              minHeight: "100vh",
              height: "100%",
              maxWidth: 932,
              mx: "auto",
            }}
          >
            {isUpLaptop && <Header />}
            <Routes>
              <Route path="/" element={<Navigate to={`/Home`} />} />
              <Route
                path={`*`}
                element={<Navigate to={`/shared-trips`} state={{ from: location }} />}
              />
              <Route
                path={`/shared-trips`}
                element={<LazyLoadPage children={<MainPage />} />}
              />
            </Routes>
            <Footer />
          </Container>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default App;
