import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    py: 4,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 100,
    bgcolor: basicTheme.palette.mainPalette.secondaryBlack,
  },
  logo: {
    display: "flex",
    alignItems: "center",
    columnGap: 1,
    textDecoration: "none",
  },
  logoTitle: {
    textDecoration: "none",
  },
  logoIcon: {
    width: 33,
    height: 33,
  },
  button: {
    px: 4,
    py: 1.25,
    borderRadius: 4,
    bgcolor: basicTheme.palette.mainPalette.blue,
    "&:hover": {
      bgcolor: basicTheme.palette.mainPalette.blue,
    },
    [basicTheme.breakpoints.up("tablet")]: {
      bgcolor: basicTheme.palette.mainPalette.white,
      "&:hover": {
        bgcolor: basicTheme.palette.mainPalette.white,
      },
    }
  },
  logoText:{
    display: "flex",
    flexDirection: "column",
  }
};
