import React, { FC } from "react";

import { Box } from "UI/Box";
import { Link } from "UI/Link";
import { Typography } from "UI/Typography";
import DownloadIcon from "assets/DownloadIcon.svg";
import Image from "mui-image";

import { styles } from "./styles";

import { apiStoreLink } from "utils";

export interface FooterProps {}

export const Footer: FC<FooterProps> = React.memo(() => {
  return (
    <Box sx={styles.root}>
      <Typography variant="caption" color="mainPalette.primaryGrey">
        Tuluminati © 2024
      </Typography>
      <Link href={apiStoreLink} target="_blank" sx={styles.logo}>
        <Image src={DownloadIcon} duration={0} fit="cover" />
      </Link>
    </Box>
  );
});
