import { createTheme } from "@mui/material";

const InterFontFamily = "'Inter', sans-serif";

export const basicTheme = createTheme({
  palette: {
    background: {
      default: "transparent",
    },
    mainPalette: {
      primaryBlack: "#1F2123",
      secondaryBlack: "#161718",
      primaryGrey: "rgba(255, 255, 255, 0.60)",
      secondaryGrey: "rgba(255, 255, 255, 0.12)",
      white: "#FFF",
      blue: "#3477F9",
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 744,
      laptop: 1240,
      desktop: 1440,
      large: 1920,
    },
  },
  typography: {
    fontFamily: InterFontFamily,
    h1: {
      fontFamily: InterFontFamily,
      fontWeight: 700,
      fontSize: "34px",
      lineHeight: "37px",
    },
    h2: {
      fontFamily: InterFontFamily,
      fontWeight: 900,
      fontSize: "30px",
      lineHeight: "26px",
      letterSpacing: "-1.5px",
    },
    h3: {
      fontFamily: InterFontFamily,
      fontWeight: 600,
      fontSize: "26px",
      lineHeight: "normal",
      letterSpacing: "-0.32px",
    },
    h4: {
      fontFamily: InterFontFamily,
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "normal",
      letterSpacing: "5px",
    },
    buttonText: {
      fontFamily: InterFontFamily,
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "21px",
    },
    bodyText: {
      fontFamily: InterFontFamily,
      fontWeight: 400,
      fontSize: "17px",
      lineHeight: "21px",
    },
    caption: {
      fontFamily: InterFontFamily,
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "normal",
    },
  },
  spacing: 4,
});

export const theme = createTheme(
  {
    components: {
      MuiTypography: {
        defaultProps: {
          variant: "bodyText",
          color: "#FFF",
          variantMapping: {
            h1: "h1",
            h2: "h2",
            h3: "h3",
            h4: "h4",
            h5: "h5",
            h6: "h6",
            subtitle1: "h2",
            subtitle2: "h2",
            buttonText: "p",
            bodyText: "p",
            caption: "p",
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: false,
        },
        styleOverrides: {
          root: {
            margin: 0,
            paddingTop: 18,
            paddingBottom: 18,
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: 0,
            textTransform: "none",
            color: "inherit",
            "&[disabled]": {
              color: "#8D969D",
              backgroundColor: "#D1D5D8",
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecorationColor: "unset",
            textDecorationThickness: 1,
            textUnderlineOffset: 1.5,
            "&:disabled": {
              color: "lightSecondaryGrey",
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            transform: "scale(1)",
          },
        },
      },
    },
  },
  basicTheme
);
