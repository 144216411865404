import React, { FC } from "react";

import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Typography } from "UI/Typography";
import { AppLogo } from "components/AppLogo";

import { styles } from "./styles";

import { useBreakpoints } from "hooks/useBreakpoints";
import { apiStoreLink } from "utils";

export interface HeaderProps {}

export const Header: FC<HeaderProps> = React.memo(() => {
  const { isUpTablet } = useBreakpoints();
  return (
    <Box sx={styles.root}>
      <AppLogo variant={isUpTablet ? "primary" : "secondary"} />
      <Button
        href={apiStoreLink}
        target="_blank"
        disableRipple
        sx={styles.button}
      >
        <Typography
          variant="buttonText"
          color={isUpTablet ? "mainPalette.primaryBlack" : "mainPalette.white"}
        >
          Download
        </Typography>
      </Button>
    </Box>
  );
});
