import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    pt: 4,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    width: "100%",
    bgcolor: basicTheme.palette.mainPalette.secondaryBlack,
    mt: 6,
    borderTop: `1px solid ${basicTheme.palette.mainPalette.secondaryGrey}`,
    [basicTheme.breakpoints.up("tablet")]: {
      mt: 10,
    },
  },
  logo: {
    width: 120,
    height: 40,
  },
};
