import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    display: "flex",
    alignItems: "center",
    columnGap: 1,
    textDecoration: "none",
  },
  logoTitle: {
    textDecoration: "none",
  },
  logoIcon: {
    width: 33,
    height: 33,
  },
  logoText: {
    display: "flex",
    flexDirection: "column",
  },
};
