import React, { FC } from "react";

import { Box } from "UI/Box";
import { Link } from "UI/Link";
import { Typography } from "UI/Typography";
import MainLogo from "assets/MainLogo.svg";
import Image from "mui-image";

import { styles } from "./styles";

import { apiStoreLink } from "utils";

export interface AppLogoProps {
  variant?: "primary" | "secondary";
}

export const AppLogo: FC<AppLogoProps> = React.memo(
  ({ variant = "primary" }) => {
    return (
      <Link
        href={apiStoreLink}
        target="_blank"
        sx={styles.root}
      >
        <Box sx={styles.logoIcon}>
          <Image src={MainLogo} duration={0} fit="cover" />
        </Box>
        <Box sx={styles.logoText}>
          <Typography
            variant={variant === "primary" ? "h4" : "caption"}
            color="mainPalette.white"
            sx={styles.logoTitle}
          >
            TULUMINATI
          </Typography>
          {variant === "secondary" && (
            <Typography color="mainPalette.primaryGrey" variant="caption">
              Open in the tuluminati app
            </Typography>
          )}
        </Box>
      </Link>
    );
  }
);
